import { Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";

export interface ParamsSyncData { key: string; value: Ref<any> }
export const useParamsSync = (...params: ParamsSyncData[]) => {

  const route = useRoute();
  const router = useRouter();
  const existingQuery = route.query;

  params.forEach(param => {
    if (existingQuery[param.key]) {

      // If the existing param is a number, then assign it as a number
      if (typeof param.value.value === "number") {
        param.value.value = Number(existingQuery[param.key])
      } else {
        param.value.value = existingQuery[param.key]
      }
    }
  })

  // Convert params to query
  const query = params.reduce((acc: any, param) => {
    acc[param.key] = param.value.value
    return acc
  }, {})


  // Update the query part of the URL
  router.replace({ path: route.path, query }).catch(() => { /* Ignore */ });

  watch(() => params, (val) => {
    // console.log("params changed", val.map(v => v.value.value))
    const query = val.reduce((acc: any, param) => {
      acc[param.key] = param.value.value
      return acc
    }, {})

    router.replace({ path: route.path, query }).catch(() => { /* Ignore */ });

  }, { deep: true })
}