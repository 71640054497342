<template>
  <section>
    <div class="bg-white quick-stats-section d-none d-md-block">
      <h6
        style="text-align: left; font-weight: 600; color: var(--el-app-primary)"
      >
        Statistics
      </h6>

      <div class="d-none d-md-flex justify-content-center">
        <carousel
          :autoWidth="true"
          :dots="false"
          :navText="[
            `<i class='el-icon-arrow-left'></i>`,
            `<i class='el-icon-arrow-right'></i>`,
          ]"
          style="display: flex"
        >
          <div class="quick-stat-item">
            <div>
              <span style="color: #eeeeee; font-size: 50px">
                <strong>{{ total_classes_today }}</strong> </span
              ><br />
              <small style="color: #eeeeee; font-size: 17px"
                >Classes Today</small
              >
            </div>
          </div>

          <div class="quick-stat-item">
            <router-link to="/dashboard/students">
              <div>
                <span style="color: #eeeeee; font-size: 50px"
                  ><strong>{{ assigned_modules }}</strong></span
                ><br />
                <small style="color: #eeeeee; font-size: 17px"
                  >Assigned Modules</small
                >
              </div>
            </router-link>
          </div>
          <div class="quick-stat-item">
            <router-link to="/dashboard/staff">
              <div>
                <span style="color: #eeeeee; font-size: 50px"
                  ><strong>{{ pending_resources }}</strong></span
                ><br />
                <small style="color: #eeeeee; font-size: 17px"
                  >Pending Resources</small
                >
              </div>
            </router-link>
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
// @ts-ignore
import carousel from "vue-owl-carousel";

export default {
  components: { carousel },

  data() {
    return {};
  },
  props: {
    total_classes_today: {
      type: Number,
      required: true,
    },
    assigned_modules: {
      type: Number,
      required: true,
    },
    pending_resources: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.bg-white {
  background-color: white;
}

.quick-stats-section {
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
}

.quick-stat-item {
  background-color: var(--vu-red);
  height: 150px;
  width: 200px;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .quick-stats-section {
    border-radius: 3px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .quick-stats-section {
    border-radius: 3px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}
</style>

<style>
.owl-prev {
  position: absolute;
  left: -21px;
  top: 39%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 39%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-nav.disabled {
  display: block !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme {
  /* background:rgba(0, 0, 0, 0.9); */
  display: flex;
  align-items: center;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }

  .owl-theme {
    /* background:rgba(0, 0, 0, 0.9); */
    display: flex;
    align-items: center;
  }

  .owl-theme:hover .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #4b88dd;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }

  .owl-theme {
    /* background:rgba(0, 0, 0, 0.9); */
    display: flex;
    align-items: center;
  }

  .owl-theme:hover .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #4b88dd;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
}
</style>
