<template>
  <section class="container">
    <div class="content">
      <div class="row w-100 mb-4">
        <div class="col-12 pl-3 px-0 mb-0 text-left">
          <BreadCrumbComponent :pageTitles="['Tutorials', $route.params.tutorialName]" />
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12 text-left">
          <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); 
                        margin-bottom: 15px;">
            <strong>{{$route.params.tutorialName}}</strong>
          </div>
        </div>
      </div>
      <div
        class="row mx-auto"
        style="width: 100%"
      >
        <div class="col-12 px-0 mt-3">
          <!-- <vue-plyr>
                        <video controls playsinline>
                            <source :src="$route.params.videoSource" type="video/mp4" />
                            <track default kind="captions"
                            label="English captions" srclang="en"/>
                        </video>
                    </vue-plyr> -->

          <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
          <vue-plyr v-if="isYouTubeVideo($route.params.videoSource)">
            <div class="plyr__video-embed">
              <iframe
                :src="$route.params.videoSource"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              ></iframe>
            </div>
          </vue-plyr>

          <vue-plyr v-else>
            <video
              controls
              playsinline
            >
              <source
                :src="$route.params.videoSource"
                type="video/mp4"
              />
              <track
                default
                kind="captions"
                label="English captions"
                srclang="en"
              />
            </video>
          </vue-plyr>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
// import carousel from "vue-owl-carousel";
export default {
  components: { BreadCrumbComponent },
  computed: {
    isYouTubeVideo() {
      return (sourceUrl) => {
        return sourceUrl.includes("https://youtu");
      };
    },
  },
  data() {
    return {
      carouselKey: 1,
      thesrc: "https://www.youtube.com/watch?v=mmU56vZs72A&t=351s",
      videoSource: "",
      tutorialName: "",
    };
  },

  created() {
    //If there is no route params, take them back to tutorials screen
    if (!this.$route.params.tutorialName || !this.$route.params.videoSource) {
      this.$router.replace({ name: "Tutorials" });
    }
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}
.ui-margin {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
</style>

<style>
hr.custom-classic-hr {
  /* Gradient transparent-color-transparent */
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.205),
    rgba(0, 0, 0, 0)
  );
}
</style>