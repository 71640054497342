<template>
  <div
    class="container-fluid px-0 h-100 my_batches_class"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Resources']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-10 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>My Intakes</strong>
              </div>
            </div>
          </div>

          <div
            style="
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="row w-100 d-flex">
              <div class="col-md-5 mb-3">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search Module"
                  spellcheck="off"
                />
              </div>
            </div>
          </div>
          <hr />

          <div class="table-responsive">
            <data-tables
              :data="batches"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [25, 50, 100] }"
              type="expand"
              :filters="filters"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#042F6C'"
                />
                <div
                  @click="getBatches"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Batches Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Batches</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Module"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
                prop="name"
              >
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.course_unit.course_unit_code }} -
                    {{ scope.row.course_unit.title }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="Cohort"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
                prop="name"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.course_intake_batch.course.course_code }}
                    {{ toMonthName(scope.row.course_intake_batch.month) }}/{{
                      scope.row.course_intake_batch.year
                    }}
                    {{ scope.row.course_intake_batch.cohort }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="Students"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="160px"
              >
                <template slot-scope="scope">
                  <div
                    @click="
                      showStudents(
                        scope.row.course_intake_batch.course_intake_batch_id
                      )
                    "
                    style="cursor: pointer; width: 100%; height: 100%"
                  >
                    <el-button type="primary" round>
                      <span style="font-weight: 700"
                        >{{ scope.row.course_intake_batch.batch_students }}
                        {{
                          scope.row.course_intake_batch.batch_students == 1
                            ? "Student"
                            : "Students"
                        }}
                      </span>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </section>
    </article>
    <StudentsInIntakeBatchDialog
      :is-visible="dialogVisible"
      :batch-id="selectedIntakeBatchId"
      @on-close="closeStudentsInIntakeBatchDialog"
    />
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";
import StudentsInIntakeBatchDialog from "@/components/students-in-this-batch-dialog.vue";

export default {
  components: { ScaleOut, BreadCrumbComponent, StudentsInIntakeBatchDialog },

  data() {
    return {
      dialogVisible: false,

      loading: false,
      loadingError: false,
      responseData: "",
      selectedIntakeBatchId: "",
      batches: [],
      searchBy: "Search by course title",
      tableProps: {
        border: true,
        stripe: true,
      },
      filters: [
        {
          prop: "",
          value: "",
          filterFn: (row, filterItem) => {
            const value = filterItem.value.toLowerCase().trim();

            return (
              row.course_unit.title.toLowerCase().includes(value) ||
              row.course_unit.course_unit_code.toLowerCase().includes(value)
            );
          },
        },
      ],
    };
  },

  mounted() {
    this.getBatches();
  },

  methods: {
    toMonthName(monthNum) {
      return getMonthName(monthNum, false).toUpperCase();
    },

    async getBatches() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `staff/lecturer-batches-with-course-units/${this.$store.state.userId}`
        );

        if (
          request.data.success &&
          request.data.message ==
            "LECTURER_COURSE_UNITS_WITH_BATCHES_FETCHED_SUCCESSFULLY"
        ) {
          this.batches = request.data.course_units_with_batches;
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Batches",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    showStudents(batchId) {
      this.dialogVisible = true;
      this.selectedIntakeBatchId = batchId;
    },

    closeStudentsInIntakeBatchDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.profile_img img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.to-an-array {
  display: flex;
}

.to-an-array > div {
  margin-right: 20px;
  background-color: #eeeeee;
  padding: 10px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#myInput {
  background-image: url("/css/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 14px;
  padding: 5px 20px 5px 40px;
  border: 1px solid #ddd;
}

#myTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
}

#myTable th,
#myTable td {
  text-align: left;
  padding: 12px;
}

h2 {
  text-align: center;
  padding: 20px 0;
}

.table-bordered {
  border: 1px solid #ddd !important;
}

table caption {
  padding: 0.5em 0;
}

@media screen and (max-width: 767px) {
  table caption {
    display: none;
  }
}

.p {
  text-align: center;
  padding-top: 140px;
  font-size: 14px;
}

th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
.my_batches_class .el-input input {
  height: calc(1.55rem) !important;
  padding: 0.075rem 0.05rem !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.my_batches_class .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}
</style>
