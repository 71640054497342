<template>
  <section>
    <div class="todaySection">
      <div style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
        <div>
          <small style="
              text-align: left;
              font-weight: 600;
              color: var(--el-app-primary);
            ">Upcoming Lectures</small>
        </div>
      </div>
      <div
        v-if="our_upcoming_lectures.length == 0"
        class="text-left mt-2"
        style="font-size: 14px; font-weight: 400"
      >
        You have no upcoming Lectures
      </div>
      <div v-else-if="our_upcoming_lectures.length != 0">
        <div
          v-for="lecture in our_upcoming_lectures"
          :key="lecture.live_lecture_id"
          style="
            cursor: pointer;
            margin-top: 10px;
            padding: 10px;
            background-color: #eeeeee;
            border-radius: 3px;
          "
        >
          <div style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <div style="text-align: left">

              <div v-if="lecture.course_units_with_batch.length > 3">
                <small><strong>{{ lecture.course_units_with_batch.length }} Modules</strong></small>
              </div>

              <div v-else>
                <div
                  v-for="(courseUnit, index) in lecture.course_units_with_batch"
                  :key="index"
                >
                  <small><strong>{{ courseUnit }}</strong></small>
                </div>
              </div>

              <br />
              <small>{{ lecture.title }}</small>
            </div>
            <div style="
                text-align: left;
                font-size: 0.88em;
                color: rgba(0, 0, 0, 0.5) !important;
              ">
              <small>{{ lecture.start_time.toUpperCase() }} -
                {{ lecture.end_time.toUpperCase() }}</small>
              <div
                v-if="lecture.date_relative == 0"
                style="text-right: left"
              >
                <small>Today</small>
              </div>
              <div
                v-else
                style="text-right: left; color: rgba(0, 0, 0, 0.5) !important"
              >
                <small>{{ lecture.date }} </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    our_upcoming_lectures: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.todaySection {
  padding: 20px;
}
.bg-white {
  background-color: white;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .todaySection {
    padding: 10px;
  }
  .bg-white {
    background-color: white;
  }
}
</style>
