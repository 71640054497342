<template>
  <div
    class="container-fluid px-0 h-100"
    style="text-align: left;"
  >
    <article>
      <section style="padding-top: 50px;">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Examinations', 'Statistics']" />
            </div>
          </div>

          <div class="row w-100">
            <div
              class="col-12"
              style=" display: flex; align-items: flex-end; justify-content: space-between;"
            >
              <div style=" font-size: 20px; font-weight: 400; color: var(--el-app-primary);">
                <strong>Examination Statistics for today.</strong>
              </div>
            </div>
          </div>

          <div class="search_add_section"> </div>
          <hr />

          <div>
            <div
              v-if="loading && !loadingError"
              style=" height: 60vh; display: flex;
                            align-items: center; justify-content: center;"
            >
              <div class="text-center">
                <ScaleOut
                  class="mt-3"
                  :background="'#164B70'"
                />
                <br />
                <span style="font-weight: 500">Loading Examination Statistics...</span>
              </div>
            </div>

            <div
              @click="getstatistics"
              v-else-if="!loading && loadingError"
              style="
                            height: 60vh;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer"
            >
              Unable to Load Examination Statistics Now. Please click here to retry
            </div>

            <div
              class="row"
              v-else
              style=""
            >
              <div
                class="col-md-4"
                style=" display: flex; align-items: center; justify-content: center;"
              >
                <!-- <el-progress type="circle" :percentage="25"></el-progress> -->
                <div class="text-center">
                  <el-progress
                    type="dashboard"
                    :percentage="percentageSubmitted"
                    :color="colors"
                  ></el-progress>
                  <p>
                    <span style="font-weight: 600;">
                      Students who have submitted.
                    </span>
                  </p>
                </div>
                <!-- <div>
                              <apexchart width="380" type="donut" :options="chartOptions" :series="series"></apexchart>
                            </div> -->
              </div>

              <div
                class="col-md-8"
                style=""
              >

                <div
                  class="block mb-4"
                  style=""
                >
                  <span class="demonstration">Choose Date</span><br />
                  <el-date-picker
                    v-model="value2"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    align="right"
                  >
                  </el-date-picker>
                  <el-button
                    type="primary"
                    @click="checkStatistics()"
                  >Check</el-button>
                </div>

                <span>
                  <span style="font-weight: 600;">
                    {{ statistics.number_of_students_started }}
                  </span>
                  Number of Students who have started the Exam
                </span><br />

                <span>
                  <span style="font-weight: 600;">
                    {{ statistics.number_of_students_who_have_submitted }}
                  </span>
                  Number of Students who have submitted:
                </span><br />
                <span>
                  <span style="font-weight: 600;">
                    {{ statistics.number_of_students_who_submitted_online }}
                  </span>
                  Number of Students who submitted Online
                </span><br />
                <span>
                  <span style="font-weight: 600;">
                    {{ statistics.number_of_students_who_submitted_by_file_upload }}
                  </span>
                  Number of Students who submitted by File Upload
                </span><br />
                <span>
                  <span style="font-weight: 600;">
                    {{ statistics.number_of_students_who_have_not_submitted_but_started }}
                  </span>
                  Number of Students who have not submitted but started
                </span><br />

              </div>

              <!-- <hr/> -->
              <div
                class="col-md-12"
                style="
                            margin-top: 30px;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            background-color: #E5E9F2;
                            "
              >
                <!-- <hr/> -->
                <div
                  class="row"
                  style=""
                >

                  <div
                    class="col-md-12"
                    style=""
                  >
                    <div>
                      <!-- <apexchart width="500" type="line" :options="options" :series="series"></apexchart> -->
                    </div>

                    <div style="">
                      <div
                        class="col-12"
                        style="display: flex; align-items: flex-end; justify-content: space-between;"
                      >
                        <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary);">
                          <strong>Weekly Statistics.</strong>
                        </div>

                        <div class="">
                          <span class="demonstration">Choose Date</span><br />
                          <el-date-picker
                            v-model="weekly_value2"
                            type="daterange"
                            :picker-options="pickerOptions"
                            range-separator="To"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            align="right"
                          >
                          </el-date-picker>
                          <el-button
                            type="primary"
                            @click="weeklyStatistics()"
                          >Check</el-button>
                        </div>
                      </div>
                      <hr />
                    </div>

                    <div>
                      <div>
                        <!-- <apexchart
                          width="500"
                          type="bar"
                          :options="chartOptions"
                          :series="series"
                        ></apexchart> -->
                      </div>
                    </div>

                    <!-- Gwe: {{ weeklyStats | json }} -->
                    <div>
                      <strong style="font-weight: 600;">Sunday</strong> : {{weeklyStats[1].date}}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>

                    </div>
                    <hr />
                    <div>
                      <strong style="font-weight: 600;">Monday</strong> : {{weeklyStats[2].date}}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>
                    </div>
                    <hr />
                    <div>
                      <strong style="font-weight: 600;">Tuesday</strong> : {{ weeklyStats[3].date }}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>
                    </div>
                    <hr />

                    <div>
                      <strong style="font-weight: 600;">Wednesday</strong> : {{ weeklyStats[4].date }}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>
                    </div>
                    <hr />

                    <div>
                      <strong style="font-weight: 600;">Thursday</strong> : {{ weeklyStats[5].date }}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>
                    </div>
                    <hr />

                    <div>
                      <strong style="font-weight: 600;">Friday</strong> : {{ weeklyStats[6].date }}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>
                    </div>
                    <hr />

                    <div>
                      <strong style="font-weight: 600;">Sarturday</strong> : {{ weeklyStats[7].date }}<br />
                      <span>
                        <span style="font-weight: 600;">
                          {{ weeklyStats[1].number_of_students_who_have_submitted }}
                        </span>
                        Number of Students who have submitted:
                      </span>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>

    </article>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../../components/bread-crumb-component";
// import VueApexCharts from "vue-apexcharts";

export default {
  components: { ScaleOut, BreadCrumbComponent },
  mixins: [validationMixin],

  data() {
    return {
      addFacultyModal: false,
      editFacultyModal: false,
      loading: false,
      loadingError: false,
      submiting: false,
      deleting: false,
      responseData: "",
      title: "",
      title_edit: "",
      statistics: {},
      totalSubmitted: "",
      totalNumberOfStudents: "",

      weeklyStats: {},
      percentage: 10,

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],

      statistics_cUnits_crud: ["AR", "VC", "SUPER_ADMIN"],

      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: [],
      weekly_value2: [],

      chartOptions: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
      },
      series: [
        {
          name: "series-1",
          data: [5, 10, 3, 30, 45, 12, 32, 40],
        },
      ],
    };
  },

  computed: {
    percentageSubmitted() {
      if (this.totalSubmitted == 0) return 0;
      return +(
        (this.totalSubmitted / this.totalNumberOfStudents) *
        100
      ).toFixed(1);
    },

    titleError() {
      if (!this.$v.title.required) {
        return "*Title is required";
      }
      return "";
    },

    titleEditError() {
      if (!this.$v.title_edit.required) {
        return "*Title is required";
      }
      return "";
    },

    createdBy() {
      return (creator) => {
        if (!creator) return "N/A";
        return `${creator.first_name} ${creator.last_name}`;
      };
    },
  },

  validations: {
    title: {
      required,
    },
    title_edit: {
      required,
    },
  },

  mounted() {
    this.getstatistics();
    this.getWeeklyStatistics();
  },

  methods: {
    handleClose(done) {
      this.clearInputs();
      this.$v.$reset();
      done();
    },

    clearInputs() {
      this.title = "";
      this.title_edit = "";
    },

    async getstatistics() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(`examinations/statistics`);
        if (
          request.data.success &&
          request.data.message == "STATISTICS_RETRIEVED_SUCCESS_FULLY"
        ) {
          this.statistics = request.data.statistics;
          this.totalNumberOfStudents =
            this.statistics.number_of_students_started;
          this.totalSubmitted =
            this.statistics.number_of_students_who_have_submitted;
        } else {
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load statistics",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async getWeeklyStatistics() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(`examinations/weekly-statistics`);
        if (
          request.data.success &&
          request.data.message == "STATISTICS_RETRIEVED_SUCCESS_FULLY"
        ) {
          this.weeklyStats = request.data.statistics;
          // this.weeklyStats = JSON.stringify(this.weeklyStats);
          console.log("Weekly Statistics: " + this.weeklyStats);
        } else {
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load statistics",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async checkStatistics() {
      alert("Hey everybody");
      alert("Start Time: " + this.value2);
      alert("Start Time: " + this.value2[0]);
      alert("End Time: " + this.value2[1]);

      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `examinations/statistics?start_time=${this.value2[0]}&end_time=${this.value2[1]}`
        );
        if (
          request.data.success &&
          request.data.message == "STATISTICS_RETRIEVED_SUCCESS_FULLY"
        ) {
          this.statistics = request.data.statistics;
        } else {
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load statistics",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async weeklyStatistics() {
      alert("Hey Weekly everybody ... ");
      alert("Date: " + this.weekly_value2);
      alert("Start Date: " + this.weekly_value2[0]);
      alert("End Date: " + this.weekly_value2[1]);

      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `examinations/weekly-statistics?start_date=${this.weekly_value2[0]}&end_date=${this.weekly_value2[1]}`
        );
        if (
          request.data.success &&
          request.data.message == "STATISTICS_RETRIEVED_SUCCESS_FULLY"
        ) {
          this.statistics = request.data.statistics;
        } else {
           this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load statistics",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

input:focus {
  border: 0.01em solid #409eff !important;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.faculty_dialogs .el-dialog {
  width: 40%;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .faculty_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .faculty_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .faculty_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .faculty_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
