import { render, staticRenderFns } from "./course-work-details.vue?vue&type=template&id=7835ac2c&scoped=true&"
import script from "./course-work-details.vue?vue&type=script&lang=ts&setup=true&"
export * from "./course-work-details.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./course-work-details.vue?vue&type=style&index=0&id=7835ac2c&prod&scoped=true&lang=css&"
import style1 from "./course-work-details.vue?vue&type=style&index=1&id=7835ac2c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7835ac2c",
  null
  
)

export default component.exports