<template>
  <div
    class="px-0 container-fluid live-lectures h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="mb-4 row w-100">
            <div class="px-0 pl-3 mb-0 text-left col-12">
              <BreadCrumbComponent :pageTitles="['Live Lectures']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                ">
                <strong>Live Lectures for Lecturer <div v-if="lecturer">({{`${lecturer.first_name} ${lecturer.last_name}`}})</div> </strong>
              </div>
            </div>
          </div>

          <div style="
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <div class="row w-100 d-flex">
              <!-- <div class="mb-3 col-md-3">
                <input
                  type="text"
                  @input="onSearch"
                  v-model.trim="searchByValue"
                  placeholder="Search by Lecturer"
                  spellcheck="off"
                  style="height: calc(2.5rem) !important"
                  class="border-color-fix"
                />
              </div> -->
              <div class="col-4">
                <el-select
                  v-model="selectedFilter"
                  @handleOptionClick="handleOptionClick"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="col-md-3">
                <GenerateLectureReport
                  :lecturerId="$route.params.id"
                  :lecturerName="lecturer ? `${lecturer.first_name} ${lecturer.last_name}`: null"
                />
              </div> -->
            </div>
          </div>
          <hr />

          <div
            v-loading="fetchingLiveClassId || (loading && live_lectures.length > 0)"
            style="width: 100%"
          >
            <data-tables-server
              :data="live_lectures"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              ref="lecturesTable"
              :total="totalLectures"
              :current-page.sync="currentPage"
              :page-size='pageSize'
              @query-change='loadData'
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getLiveLectures"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Live lectures Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Live lectures</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="60px"
              >
                <template slot-scope="scope">
                  <span>{{ ((currentPage -1 ) * pageSize) + scope.$index + 1}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Module(s)"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
                prop="course_unit_code"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.course_units_with_batch.length <6">
                    <div
                      v-for="(moduleItem, index) in scope.row.course_units_with_batch"
                      :key="index"
                    >
                      <span>{{ moduleItem }}</span>
                    </div>
                  </div>
                  <div v-else>
                    {{scope.row.course_units_with_batch.length}} Intakes
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Date"
                width="120px"
              >
                <template slot-scope="scope">
                  <span>{{ formattedDate(scope.row.date) }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Start time"
                width="90px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.start_time }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="End time"
                width="90px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.end_time }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Status"
                width="80px"
                class-name="text-center"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    v-if="
                      scope.row.has_lecturer_ended_class
                    "
                    content="Lecture was attended"
                    placement="top"
                  >
                    <i
                      class="el-icon-check color-green"
                      style="font-size: 20px;color: #ffbb33; cursor:pointer"
                    ></i>
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="scope.row.ongoing_status === 'TODAY_AND_LIVE'"
                    content="Lecture is Live"
                    placement="top"
                  >
                    <i
                      class="fas fa-play color-red"
                      style="font-size: 20px"
                    ></i>
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="
                      scope.row.date_relative == 1 ||
                      scope.row.ongoing_status === 'TODAY_AND_UPCOMING'
                    "
                    content="Upcoming"
                    placement="top"
                  >
                    <i
                      class="el-icon-alarm-clock"
                      style="font-size: 20px"
                    ></i>
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="
                      scope.row.date_relative == -1 ||
                      scope.row.ongoing_status === 'TODAY_AND_ENDED' || scope.row.class_status
                    "
                    :content="toolTipContent(scope.row)"
                    placement="top"
                  >
                    <i
                      @click="loadAttendanceInfo(scope.row.live_lecture_id)"
                      :class="{
                        'el-icon-warning-outline': !scope.row.class_status,
                        'el-icon-loading': scope.row.class_status == 'Loading',
                        'el-icon-close color-red':
                          scope.row.class_status == 'Missed',
                        'el-icon-check color-green':
                          scope.row.class_status == 'Attended',
                      }"
                      style="font-size: 20px;color: #ffbb33; cursor:pointer"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="Next Schedule"
                width="120px"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.next_schedule">
                    {{scope.row.next_schedule}}
                  </span>
                  <i
                    v-else
                    style="color: rgba(0,0,0,.5)"
                  >None</i>

                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                width="120px"
                class-name="text-center"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <div>
                    <el-tooltip
                      content="Details"
                      placement="top"
                    >
                      <el-button
                        @click="getLectureDetails(scope.row.live_lecture_id)"
                        type="primary"
                        icon="fas fa-eye"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>
    </article>

    <LectureDetailsDialog
      :lectureDetailsLoading="lectureDetailsLoading"
      :isVisible="isLectureDetailsModelVisible"
      :beforeClose="beforeDialogClose"
      :liveLectureDetails="liveLectureDetails"
      @delete-live-lecture="onDeleteLiveLecture"
    />

    <ChooseLectureRangeDialog
      :dialogVisible="chooseLectureRangeDialogVisible"
      @on-close="chooseLectureRangeDialogVisible = false"
      @on-range-chosen="onRangeChosen"
    />
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";
import LectureDetailsDialog from "../components/dialogs/lecture-details-dialog";
// import GenerateLectureReport from "../components/dialogs/generate-lecturer-report.vue";
import ChooseLectureRangeDialog from "../components/dialogs/choose-lecture-range-dialog.vue";
import weekDayToName from "../helpers/weekDayToName";

import debounce from "debounce";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    LectureDetailsDialog,
    // GenerateLectureReport,
    ChooseLectureRangeDialog,
  },

  data() {
    return {
      selectedFilter: "Show All",
      searchBy: "Search by Lecturer",
      searchByValue: "",
      lecturer: "",
      fetchingLiveClassId: null,
      isLectureDetailsModelVisible: false,
      lectureDetailsLoading: true,
      chooseLectureRangeDialogVisible: false,
      filterDateStart: null,
      filterDateEnd: null,
      searchByOptions: ["Search by Lecturer", "Search by Module"],
      loading: false,
      loadingError: false,

      totalLectures: 0,
      currentPage: 0,
      pageSize: 10,

      live_lectures: [],
      liveLectureDetails: {},
      tableProps: {
        border: true,
        stripe: true,
      },
    };
  },
  watch: {
    searchBy() {
      this.searchByValue = "";
      this.onSearch();
    },

    selectedFilter() {
      //If the selected filter is for anything other than choose custom day, then reload the table info
      if (
        this.selectedFilter == "Show All" ||
        this.selectedFilter == "Show Only Today" ||
        this.selectedFilter == "Show Only Upcoming Lectures"
      ) {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.onSearch();
      }
    },
  },
  computed: {
    filterOptions() {
      let chosenLectureDateRange;
      if (!this.filterDateStart || !this.filterDateEnd) {
        chosenLectureDateRange = "Select a date Range";
      } else {
        chosenLectureDateRange = `${format(
          parseISO(this.filterDateStart),
          "do MMMM yyyy"
        )} - ${format(parseISO(this.filterDateEnd), "do MMMM yyyy")}`;
      }

      return [
        {
          value: "Show All",
          label: "Show All",
        },
        {
          value: "Show Only Today",
          label: "Show Only Today",
        },
        {
          value: "Show Only Upcoming Lectures",
          label: "Show Only Upcoming Lectures",
        },
        {
          value: "customDate",
          label: chosenLectureDateRange,
        },
      ];
    },
    toolTipContent() {
      return (lecture) => {
        if (lecture.class_status === "Loading") {
          return "Please Wait...";
        } else if (lecture.class_status === "Attended") {
          return "Lecture was attended";
        } else if (lecture.class_status === "Missed") {
          return "Lecture was missed";
        } else return "Click to Check";
      };
    },

    toWeekName() {
      return (weekNum) => {
        return weekDayToName(weekNum);
      };
    },

    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum);
      };
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy");
    },
  },
  // mounted() {
  //   this.getLiveLectures();
  // },
  methods: {
    onRangeChosen(startAndEndTime) {
      // alert(startAndEndTime);
      this.chooseLectureRangeDialogVisible = false;
      this.filterDateStart = startAndEndTime[0];
      this.filterDateEnd = startAndEndTime[1];
      this.getLiveLectures();
    },
    handleOptionClick() {
      //Ensure that the DOM has been updated before proceeding
      this.$nextTick(() => {
        //Only react if the option is for lecture date
        if (
          this.selectedFilter != "Show All" &&
          this.selectedFilter != "Show Only Today" &&
          this.selectedFilter != "Show Only Upcoming Lectures"
        ) {
          this.chooseLectureRangeDialogVisible = true;
        }
      });
    },
    onSearch: debounce(function () {
      this.getLiveLectures();
    }, 500),

    async loadData(queryInfo) {
      if (queryInfo) {
        if (queryInfo.type == "page" || queryInfo.type == "size") {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getLiveLectures();
        }
      }
    },

    async onDeleteLiveLecture(lectureId) {
      this.lectureDetailsLoading = true;
      try {
        let request = await this.$http.delete(
          `live-lecture/delete-one/${lectureId}`
        );
        if (
          request.data.success &&
          request.data.message === "DELETED_LIVE_LECTURE_SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Lecture Cancelled",
            "The Selected Lecture was successfully cancelled."
          );
          this.isLectureDetailsModelVisible = false;

          //Remove the deleted Lecture from our array instead of makin a network request
          this.liveLectureDetails = {};
          this.getLiveLectures();
        }
        //If Lecture was attended it cant be deleted or cancelled
        else if (
          request.data.message === "LECTURE_WAS_ALREADY_ATTENDED_OR_MISSED"
        ) {
          this.showFailedMessage(
            "Lecture already attended/missed",
            "An attended Lecture can not be cancelled, unfortunately."
          );
        }
        //Means Live Lecture was deleted elswhere
        else if (
          request.data.message === "NO_LIVE_LECTURE_WITH_MATCHING_ID_FOUND"
        ) {
          this.showFailedMessage(
            "Unable to Cancel",
            "Unable to Cancel the Lecture, Please try again"
          );

          //Close the Dialog
          this.isLectureDetailsModelVisible = false;

          //Refetch All Lectures
          this.getLiveLectures();
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.showFailedMessage(
          "Unable to Cancel",
          "Unable to Cancel the Lecture, Please try again"
        );
      } finally {
        this.lectureDetailsLoading = false;
      }
    },

    beforeDialogClose() {
      this.liveLectureDetails = {};
      this.isLectureDetailsModelVisible = false;
    },

    async getLectureDetails(lectureId) {
      this.isLectureDetailsModelVisible = true;
      this.lectureDetailsLoading = true;
      try {
        let request = await this.$http.get(
          `live-lecture/fetch-one/${lectureId}`
        );
        if (
          request.data.success &&
          request.data.message === "LIVE_LECTURE_DETAILS_RETRIEVED_SUCCESSFULLY"
        ) {
          this.liveLectureDetails = request.data.live_lecture;
        } else if (
          request.data.message === "NO_LECTURE_WITH_MATCHING_ID_FOUND"
        ) {
          //If we cant get that lecture, it means probably it was deleted elsewhere, so we close the Dialog and refresh the table
          this.getLiveLectures();
          this.isLectureDetailsModelVisible = false;
          this.showFailedMessage(
            "Fetch Failed",
            "Unable to retrieve Lecture Details"
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        this.isLectureDetailsModelVisible = false;

        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Fetch Failed",
          "Unable to retrieve Lecture Details"
        );
      } finally {
        this.lectureDetailsLoading = false;
      }
    },

    async loadAttendanceInfo(lectureId) {
      // eslint-disable-next-line no-unreachable
      let lecture = this.live_lectures.find(
        (lecture) => lecture.live_lecture_id == lectureId
      );
      //If the attendance state of the live lecture was already loaded, dont check again
      if (
        lecture.class_status === "Attended" ||
        lecture.class_status === "Missed"
      )
        return;

      lecture.class_status = "Loading";

      //Make a request to get Class Attendance Info
      try {
        let request = await this.$http.get(
          `live-lecture/fetch-lecturer-class-attendance-status?live_lecture_id=${lectureId}`
        );
        if (
          request.data.success &&
          request.data.message === "LECTURE_HAS_STARTED"
        ) {
          lecture.class_status = "Attended";
        } else if (request.data.message === "LECTURE_HAS_NOT_STARTED") {
          lecture.class_status = "Missed";
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        lecture.class_status = "";

        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        if (error.response) {
          if (error.response.data.message === "LIVE_LECTURE_NOT_FOUND") {
            return this.showFailedMessage(
              "Lecture not found",
              "The selected lecture was not found, please try again."
            );
          }
        }

        this.showFailedMessage(
          "Failed",
          "Unable to fetch Attendance for the specified Lecture"
        );
      }
    },
    async getLiveLectures() {
      try {
        this.loading = true;
        this.loadingError = false;
        let show_only = "";

        if (this.selectedFilter == "Show Only Today") {
          show_only = "only_today";
        } else if (this.selectedFilter == "Show Only Upcoming Lectures") {
          show_only = "upcoming";
        }

        //If the user has added a range filter, cater for it too
        let lecturesRange = "";
        if (this.filterDateStart && this.filterDateEnd) {
          lecturesRange = `&start_date=${this.filterDateStart}&end_date=${this.filterDateEnd}`;
        }

        let request = await this.$http.get(
          `live-lecture/fetch-all/lecturer/${this.$store.state.userId}?page=${this.currentPage}&pageSize=${this.pageSize}&lecturerName=${this.searchByValue}&show_only=${show_only}${lecturesRange}`
        );
        if (
          request.data.success &&
          request.data.message == "LECTURES_RETRIEVED_SUCCESSFULLY"
        ) {
          this.live_lectures = request.data.lectures;
          this.totalLectures = request.data.totalLectures;
          this.lecturer = request.data.lecturer;
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Live Lectures",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.live-lectures .row .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.live-lectures .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}
</style>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  height: 36px !important;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.color-red {
  color: red !important;
}
.color-green {
  color: rgb(7, 206, 7) !important;
}
</style>
