<template>
  <div
    class="container-fluid px-0 h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Course Work', 'New']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 22px; font-weight: 400; margin-bottom: 15px">
                <strong>Add Course Work</strong>
              </div>
            </div>
            <div class="col-md-8 select-intake-component">
              <label style="font-weight: 600">Modules and Intake</label><br />
              <el-select
                v-model="chosenCourseUnitsWithIntakes"
                multiple
                :disabled="isBusy"
                filterable
                remote
                placeholder="Enter a module"
                :remote-method="onSearchModules"
                :loading="isSearching"
              >
                <el-option
                  v-for="item in courseUnitsWithCourseIntakeBatches"
                  :key="item.course_unit_intake_batch_combined_id"
                  :label="item.course_unit_with_intake"
                  :value="item.course_unit_intake_batch_combined_id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-12 pt-5 text-left">
              <div style="
                  font-weight: 500;
                  margin-bottom: 15px;
                  color: rgba(0, 0, 0, 0.3);
                ">
                Course Work Details
              </div>
            </div>

            <div class="col-md-4">
              <label
                for="title"
                style="font-weight: 600; font-size: 0.9em"
              >Course Work Title</label>
              <input
                maxlength="255"
                :disabled="isBusy"
                type="text"
                style="height: 42px"
                v-model.trim="examTitle"
                placeholder="Title"
              />
            </div>

            <div class="col-md-4">
              <label
                for="title"
                style="font-weight: 600; font-size: 0.9em"
              >Total Marks</label>
              <input
                maxlength="4"
                :disabled="isBusy"
                type="text"
                style="height: 42px"
                v-model.trim="totalMarks"
                placeholder="Optional"
              />
            </div>

            <div class="row w-100 mx-0 mt-4">
              <div class="col-md-4">
                <label
                  for="title"
                  style="font-weight: 600; font-size: 0.9em"
                >Start Time</label><br />
                <el-date-picker
                  v-model="startTime"
                  :disabled="isBusy"
                  :format="'dd MMMM yyyy hh:mm A'"
                  type="datetime"
                  placeholder="Select date and time"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
              <div class="col-md-4">
                <label
                  for="title"
                  style="font-weight: 600; font-size: 0.9em"
                >End Time</label><br />
                <el-date-picker
                  v-model="endTime"
                  :disabled="isBusy"
                  :format="'dd MMMM yyyy hh:mm A'"
                  type="datetime"
                  placeholder="Select date and time"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>

              <div class="col-md-8">
                <br />
                <label
                  for="instructions"
                  style="font-weight: 600"
                ><strong>Course Work Instructions
                    <i style="font-weight: 400; color: rgba(0, 0, 0, 0.5)">(Optional)</i></strong></label><br />
                <el-input
                  type="textarea"
                  :disabled="isBusy"
                  id="instructions"
                  v-model="examInstructions"
                  maxlength="1000"
                  :autosize="{ minRows: 5, maxRows: 10 }"
                  placeholder="Type Instructions here ..."
                >
                </el-input>
                <br />
              </div>

              <!-- <div
                v-if="$route.name != 'EditCourseWork'"
                class="text-left col-12"
              >

                <el-checkbox
                  :disabled="true"
                  class="mt-3"
                  v-model="isProctoredRecordAndReview"
                >
                  Mark as Record and Review Proctored Exam </el-checkbox>
              </div>
              <div class="text-left col-12">

                <el-checkbox
                  :disabled="true"
                  class="mt-1"
                  v-model="isProctoredLiveProctor"
                >
                  Mark as Proctored Exam with a Live Proctor.</el-checkbox>
              </div> -->

              <div class="col-12 pt-5 text-left">
                <div style="
                    font-weight: 500;
                    margin-bottom: 15px;
                    color: rgba(0, 0, 0, 0.3);
                  ">
                  Upload Files
                </div>
                <div
                  class="pb-2"
                  style="font-size: 0.8em"
                >
                  * Accepted File Types are DOC, DOCX, XLS, XLSX, PPT, PPTX, PDF
                  and should not be more than <nobr>100Mbs</nobr> <br />
                  * ZIP File type can also be used for Question Papers
                </div>
              </div>

              <div class="col-md-5">
                <div>
                  <label
                    for="file"
                    style="font-weight: 600; font-size: 0.9em"
                  ><strong>Question Paper</strong></label><br />
                  <el-upload
                    class="upload-demo"
                    drag
                    :disabled="isBusy"
                    :action="''"
                    :file-list="examQuestionFiles"
                    :on-remove="handleQuestionRemove"
                    :on-change="handleQuestionPreview"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isBusy">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div
                      v-else
                      style="margin-top: 30px"
                    >
                      <el-progress
                        v-if="questionPaperUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="questionPaperUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          questionPaperUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>

              <div class="col-md-5">
                <div>
                  <label
                    for="file"
                    style="font-weight: 600; font-size: 0.9em"
                  ><strong>Answer Sheet (Optional)</strong></label><br />
                  <el-upload
                    class="upload-demo"
                    drag
                    :disabled="isBusy"
                    :action="''"
                    :file-list="answerSheetsFiles"
                    :on-remove="handleAnswerRemove"
                    :on-change="handleAnswerPreview"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isBusy || !selectedAnswerFile">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div
                      v-else
                      style="margin-top: 30px"
                    >
                      <el-progress
                        v-if="answerSheetUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="answerSheetUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          answerSheetUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>

              <!-- <div class="text-left col-12">
                <el-checkbox
                  class="mt-3"
                  v-model="for_marks_only"
                >
                  For marks only
                </el-checkbox>
              </div> -->
            </div>
            <div class="row w-100 mx-0 mt-3">
              <div class="col-12">
                <!-- {{this.questionPaperUploadingProgress}} -->
                <el-button
                  @click="onSubmit"
                  v-if="!isBusy"
                  style="background-color: var(--el-app-primary); color: white"
                  class="z-depth-0 addbtn"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                >Add Course Work</el-button>

                <ScaleOut
                  style="margin-left: 50px"
                  v-else
                  :background="'#164B70'"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import { format, parseISO } from "date-fns";
import BreadCrumbComponent from "../../components/bread-crumb-component";

import ScaleOut from "@/components/scale-out-component.vue";

// import getMonthName from "../../helpers/getMonthName";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
  },
  // mixins: [validationMixin],

  data() {
    return {
      isBusy: false,
      activeStep: 0,
      totalMarks: "",
      isSearching: false,
      chosenCourseUnitsWithIntakes: [],
      examQuestionFiles: [],
      answerSheetsFiles: [],
      courseUnitsWithCourseIntakeBatches: [],
      examTitle: "",
      examInstructions: "",
      examinationType: "",
      startTime: "",
      endTime: "",
      maximumAddedTime: "",
      selectedAnswerFile: "",
      selectedQuestionFile: "",
      isProctoredRecordAndReview: false,
      isProctoredLiveProctor: false,
      questionPaperUploadingProgress: 0,
      answerSheetUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      // for_marks_only: false,
    };
  },

  computed: {
    isProctored() {
      return this.isProctoredRecordAndReview || this.isProctoredLiveProctor;
    },
    examType() {
      if (!this.isProctored) return null;
      return this.isProctoredRecordAndReview
        ? "record_review"
        : "live_proctoring";
    },
  },

  validations: {},

  mounted() {},

  methods: {
    async onSearchModules(query) {
      if (query !== "" && query.length >= 2) {
        this.isSearching = true;
        try {
          let request = await this.$http.get(
            `course-units/search-course-unit?search_query=${query}&lecturer_id=${this.$store.state.userId}`
          );
          if (
            request.data.success &&
            request.data.message == "SEARCH RESULTS RETRIEVED SUCCESSFULLY"
          ) {
            this.courseUnitsWithCourseIntakeBatches =
              request.data.search_results;
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected API response while getting server response",
              request.data,
              request
            );
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          // console.log(error);
          this.showFailedMessage(
            "Loding Failed",
            "Unable to search Modules now, Please try again later"
          );
        } finally {
          this.isSearching = false;
        }
      }
    },

    handleQuestionPreview(file) {
      this.examQuestionFiles = [file];
      this.selectedQuestionFile = file;
    },
    handleQuestionRemove() {
      this.selectedQuestionFile = null;
    },

    handleAnswerPreview(file) {
      this.answerSheetsFiles = [file];
      this.selectedAnswerFile = file;
    },
    handleAnswerRemove() {
      this.selectedAnswerFile = null;
    },

    validateInputs(){
      if (!this.chosenCourseUnitsWithIntakes.length) {
          return this.showWarningMessage(
            "Modules Required",
            "Modules for this Course Work are required"
          );
        }

        if (!this.examTitle) {
          return this.showWarningMessage(
            "Exam Title Required",
            "The Title for this Course Work are required"
          );
        }
        if (this.examTitle.length > 255) {
          return this.showWarningMessage(
            "Exam Title too long",
            "The Title for this Course Work is too long. It should be less than 255 Characters"
          );
        }

        if (!this.totalMarks) {
          return this.showWarningMessage(
            "Course Work Total Marks Required",
            "The Total Marks for this course work is required"
          );
        }
        if (
          this.totalMarks &&
          (isNaN(+this.totalMarks) ||
            +this.totalMarks > 100 ||
            +this.totalMarks <= 0)
        ) {
          return this.showWarningMessage(
            "Total Marks Invalid",
            "When supplied, total marks should be a number between 0 and 100"
          );
        }

        if (!this.startTime || !this.endTime) {
          return this.showWarningMessage(
            "Exam Start & End Time Required",
            "The Exam Start & End Time are both Required "
          );
        }

        if (+this.startTime >= +this.endTime) {
          return this.showWarningMessage(
            "Invalid Time",
            "The End Time can not be before or equal to the Start Time, please review it and try again."
          );
        }

        if (this.examInstructions && this.examInstructions.length > 1000) {
          return this.showWarningMessage(
            "Instructions too Long",
            "Course Work Instructions are too long. It should be less than 1000 Characters"
          );
        }

        //If the exam is proctored, then ensure it is only for max of 3 Hours
        if (this.isProctored) {
          let examLength = this.endTime.getTime() - this.startTime.getTime();

          if (examLength > 3600000 * 3) {
            return this.showWarningMessage(
              "Proctored Exam Length",
              "The Length of a Proctored Exam can not be more than 3 Hours."
            );
          }
        }

        if (!this.selectedQuestionFile) {
          return this.showWarningMessage(
            "Question Paper is required",
            "A Question Paper is required for this Course Work."
          );
        }

        if (this.selectedQuestionFile.raw.size > 100 * 1024 * 1024) {
          return this.showWarningMessage(
            "File Too Big",
            "The Question Paper File you are trying to upload is too Big. Please choose another one below 100Mbs."
          );
        }

        //If answer sheet is supplied, Ensure it is not more than 100Mbs
        if (
          this.selectedAnswerFile &&
          this.selectedAnswerFile.size > 100 * 1024 * 1024
        ) {
          return this.showWarningMessage(
            "File Too Big",
            "The Answer Sheet File you are trying to upload is too Big. Please choose another one below 100Mbs."
          );
        }
      return true;
    },

    async onSubmit(){
      try {
        if (this.validateInputs() !== true) {
        return;
      }
        this.isBusy = true;

              //Since all the data is valid, then request for upload urls
        const uploadUrls = await this.requestUploadUrls();

        //Since we have got both URLs, we then need to upload the Question Paper first
        const questionPaperPathUrl = await this.uploadResourceToAWS({
          aws_upload_url: uploadUrls.question_paper.upload_url,
          selected_file: this.selectedQuestionFile.raw,
          onProgress: (progressEvent) => {
            this.questionPaperUploadingProgress = +(
              (progressEvent.loaded / progressEvent.total) *
              100
            ).toFixed(0);
          },
        });

        //If the user chose an answer sheet too, upload it
        const answerSheetPathUrl = this.selectedAnswerFile
          ? await this.uploadResourceToAWS({
              aws_upload_url: uploadUrls.answer_sheet.upload_url,
              selected_file: this.selectedAnswerFile.raw,
              onProgress: (progressEvent) => {
                this.answerSheetUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            })
          : null;


        await this.addCourseWork(questionPaperPathUrl, answerSheetPathUrl, uploadUrls)

      } catch (error) {
        this.showFailedMessage(
          "Unable to add Examination",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.isBusy = false;
        this.questionPaperUploadingProgress = 0;
        this.answerSheetUploadingProgress = 0;
      }
    },

    async addCourseWork(questionPaperPathUrl, answerSheetPathUrl, uploadUrls){
      ///Since we have uploaded all we need, we then can add the examination
      let request = await this.httpRequest({
        method: "POST",
        url: "course-work/add",
        loadingPropertyName: "isBusy",
        errorLoadingPropertyName: null,
        showSuccessMessage: true,
        body: {
          course_work_set_id: this.$route.params.courseWorkSetId,
          title: this.examTitle,
          total_marks: this.totalMarks,
          question_paper_path_url: questionPaperPathUrl,
          question_paper_path_key: uploadUrls.question_paper.path_key,
          answer_sheet_path_url: this.selectedAnswerFile ? answerSheetPathUrl : undefined,
          answer_sheet_path_key: uploadUrls.answer_sheet ? uploadUrls.answer_sheet.path_key : undefined,
          available_from: this.startTime,
          available_upto: this.endTime,
          instructions: this.examInstructions,
          question_paper_mime_type: this.selectedQuestionFile.raw.type,
          course_units_and_batches: this.chosenCourseUnitsWithIntakes.map(
            (item) => {
              /* Since the Id is intentionally concatenated with the batch and course unit separated by an underscore
                since an exam can be on the same intake but multiple course units, it causes vue v-for duplication errors
                but the combination of the intake and course unit is always unique
              we split it before we send to the API */
              const itemParts = item.split("_");
              return {
                course_intake_batch_id: itemParts[0],
                course_unit_id: itemParts[1],
              };
            }
          ),
          // for_marks_only: this.for_marks_only,
        }
      });

      if (
        request &&
        request.success &&
        request.message == "COURSE_WORK SAVED SUCCESSFULLY"
      ) {
        this.$router.push({path: `/dashboard/course-work/${this.$route.params.courseWorkSetId}`});
      }
    },

    async uploadResourceToAWS({ aws_upload_url, selected_file, onProgress }) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: onProgress,
          // onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          return request.headers.location;
        } else {
          throw "UNABLE TO UPLOAD FILE TO AWS";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Resource Now, please try again"
        );
      } finally {
        // this.isUploadingFile = false;
      }
    },

    async requestUploadUrls() {
      let request = await this.$http.post(
        "course-work/request-for-file-upload-urls",
        {
          question_paper: {
            file_name: this.selectedQuestionFile.raw.name,
            file_size: this.selectedQuestionFile.raw.size,
            mime_type: this.selectedQuestionFile.raw.type,
          },
          ...(this.selectedAnswerFile && {
            answer_sheet: {
              file_name: this.selectedAnswerFile.raw.name,
              file_size: this.selectedAnswerFile.raw.size,
            },
          }),
        }
      );

      if (
        request.data.success &&
        request.data.message == "UPLOAD URLS GENERATED"
      ) {
        return {
          question_paper: request.data.question_paper,
          answer_sheet: request.data.answer_sheet,
        };
      } else {
        this.$rollbar.warning(
          "ADMIN FRONT END: Unexpected API response while getting server response",
          request.data,
          request
        );
        throw "UNEXPECTED_RESPONSE";
      }
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-select {
  width: 100% !important;
}

.el-select__input {
  width: 100% !important;
}

/* .el-select .el-input__inner {
    cursor: pointer;
    height: 100px !important;
    width: 100% !important;
    padding-left: 10px !important;
  } */

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>
