<template>
  <section class="container live_lecture_class">
    <div class="content">
      <div class="row w-100 mb-4">
        <div class="col-12 pl-3 px-0 mb-0 text-left">
          <BreadCrumbComponent :pageTitles="['Live Lecture']" />
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12 text-left">
          <div
            v-if="classUrl != ''"
            class="embed-responsive embed-responsive-16by9"
          >
            <!-- <iframe
              id="student_live"
              :src="classUrl"
              class="embed-responsive-item"
              allow="camera *; microphone *;"
              allowfullscreen
              sandbox="allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts allow-same-origin allow-forms "
            ></iframe> -->
            <iframe
              id="student_live"
              :src="classUrl"
              class="embed-responsive-item"
              allow="camera *; microphone *;"
              @load="onLoad"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import AvailableTestsAndExamsComponent from "../components/available-tests-and-exams-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
export default {
  components: {
    // AvailableTestsAndExamsComponent,
    BreadCrumbComponent,
  },
  data() {
    return {
      classUrl: "",
      // titles: [
      //   { prop: "title", label: "Title" },
      //   { prop: "username", label: "Username" },
      //   { prop: "gender", label: "Gender" },
      //   { prop: "access_level", label: "Access Level" },
      //   { prop: "last_login", label: "Last Login" },
      // ],
    };
  },

  methods: {
    onLoad() {
      
    },
  },

  mounted() {
    // Listen for messages
    window.addEventListener("message", function (response) {
      // Make sure message is from our iframe, extensions like React dev tools might use the same technique and mess up our logs
      if (response.data && response.data.source === "iframe") {
        // Do whatever you want here.
        
      }
    });
    // window.onbeforeunload = function () {
    //   return "This will end your session";
    // };
    // this.classUrl = this.$route.params.classUrl;

    //If there's nothing in the route params, check local storage
    if (!this.$route.params.classUrl) {
      let classUrlFromStorage = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("lecturerClassUrl");
      this.classUrl = classUrlFromStorage;
    }

    //Else if we have a route param, check if we need or may not to update Local Storage
    else {
      let classUrlFromStorage = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("lecturerClassUrl");
      if (classUrlFromStorage != this.$route.params.classUrl) {
        (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("lecturerClassUrl", this.$route.params.classUrl);
      }
      this.classUrl = this.$route.params.classUrl;
    }
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}
.ui-margin {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>


<style>
th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
.live_lecture_class .el-input input {
  height: calc(1.55rem) !important;
  padding: 0.075rem 0.05rem !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.live_lecture_class .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}
</style>